import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { IHotelSearchProps } from "../pageHelpers/HotelSearch/HotelSearchProps";
import { useAppDispatch, useAppSelector } from "../hooks";
import { searchSelector, setSearch } from "../redux/slices/Search/search";
import { getLoggedStatus, getQueryParameters } from "../utils/helpers";
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";

import SearchResult from "../components/common/Search/SearchResult/SearchResult";
import { Container } from "../pageHelpers/HotelSearch/HotelSearchHelpers";
import ParagraphLayout from "../components/common/ParagraphLayout/ParagraphLayout";
import { getParagraph } from "../utils/paragraphHelpers";
import { addPageTypeInGTMDataLayer } from "../utils/datalayers";
import { Constants } from "../@types/Constants";
import DeepLinking from "../components/common/DeepLinking/DeepLinking";
import useBrandBias from "../hooks/useBrandBias";
//import { addKoddiPageTag } from "../utils/KoddiTagsGTM";

const HotelSearch = (props: IHotelSearchProps) => {
  const page = props.data.page;
  const unlockBannerShow = page ? page.field_show_unlock_banner : false;
  const fieldShowFilter = page ? page.field_show_filter : false;
  const paragraphsAboveSearch =
    page && page.relationships?.paragraphs
      ? page.relationships.paragraphs.map(getParagraph)
      : [];
  const paragraphsBelowSearch =
    page && page.relationships?.paragraphsBelowSearch
      ? page.relationships.paragraphsBelowSearch.map(getParagraph)
      : [];
  const dispatch = useAppDispatch();
  const searchData = useAppSelector(searchSelector);
  const [blockLocationAccess, setBlockLocationAccess] = useState(false);
  const hotelSearchFilters = props.data.allRlhsite.edges.length
    ? props.data.allRlhsite.edges[0].node.field_filter_selected
    : [];

  useBrandBias();
  useEffect(() => {
    if (searchData.searchString != "My Current Location") {
      setBlockLocationAccess(false);
    }
  }, [searchData]);

  useEffect(() => {
    try {
      addPageTypeInGTMDataLayer("search");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  // Initialize Search.
  useEffect(() => {
    const getPosition = (position: { coords: any }) => {
      const coordinates = position.coords;
      const payload = {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        searchString: "My Current Location",
        searchType: "",
      };

      coordinates && dispatch(setSearch(payload));
    };
    const getPositionError = () => {
      setBlockLocationAccess(true);
    };
    (async () => {
      const queryParams = getQueryParameters(location.search);
      if (queryParams !== null && queryParams.nearme === true) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            getPosition,
            getPositionError
          );
        }
      }
    })();
  }, []);

  const _pageBanner = paragraphsAboveSearch.filter(
    (p: { props: { paragraphtype: string } }) => {
      return p
        ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_BANNER
        : false;
    }
  );
  return (
    <Layout>
      <SEO
        title="Hotel Search"
        includeLeaflet
        koddiTitle="Hotel-Search"
        noIndex
      />
      <DeepLinking location={props.location} pageName={"hotel-search"} />
      {_pageBanner}
      <ParagraphLayout {...paragraphsAboveSearch} />
      <Container>
        <SearchResult
          blockLocationAccess={blockLocationAccess}
          location={props.location}
          fieldShowFilter={fieldShowFilter}
          unlockBannerShow={unlockBannerShow}
          hotelSearchFilters={hotelSearchFilters}
          pageType="hotel-search"
        />
      </Container>
      <ParagraphLayout {...paragraphsBelowSearch} />
    </Layout>
  );
};
export const pageQuery = graphql`
  query HotelSearchQuery {
    page: nodePage(path: { alias: { eq: "/v1/hotel-search" } }) {
      id
      field_show_unlock_banner
      field_show_filter
      relationships {
        paragraphs: field_sections {
          type: __typename
          ...ParagraphPageBanner
          ...ParagraphPageIntro
          ...ParagraphMediaCta
          ...ParagraphPageTeaser
          ...ParagraphTeaserList
          ...ParagraphBody
          ...ParagraphFrequentlyAskedQuestions
        }
        paragraphsBelowSearch: field_sections_below_search {
          type: __typename
          ...ParagraphPageIntro
          ...ParagraphMediaCta
          ...ParagraphPageTeaser
          ...ParagraphTeaserList
          ...ParagraphBody
          ...ParagraphFrequentlyAskedQuestions
        }
      }
    }
    allRlhsite(filter: { machine_name: { eq: "sonesta" } }) {
      edges {
        node {
          field_filter_selected
        }
      }
    }
  }
`;
export default HotelSearch;
